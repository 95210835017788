// 传感器分类接口
import {
	axios
} from '../../utils/http.js'

const ClassApi = {
	dataSouList: '/v3/DataSource/List',//数据源接口
	dataSouAdd: '/v3/DataSource/Add',//数据源接口
	dataSouEdit: '/v3/DataSource/Edit',//数据源接口
	dataSouDel: '/v3/DataSource/Del',//数据源接口
}
//左侧菜单列表
export function dataSouDel(data) {
	return axios({
		url: ClassApi.dataSouDel,
		method: 'post',
		data:data
	})
}
//左侧菜单列表
export function dataSouAdd(data) {
	return axios({
		url: ClassApi.dataSouAdd,
		method: 'post',
		data:data
	})
}
//左侧菜单列表
export function dataSouEdit(data) {
	return axios({
		url: ClassApi.dataSouEdit,
		method: 'post',
		data:data
	})
}
//左侧菜单列表
export function dataSouList(data) {
	return axios({
		url: ClassApi.dataSouList,
		method: 'post',
		data:data
	})
}
