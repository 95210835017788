<template>
	<div class="dataSource">
		<div class="dataSource-T">
			<div class="dataSourceL">
				<Breadcrumb></Breadcrumb>
				<div class="RightsManagement-searchs">
					<div class="RightsManagement-searchs1">
						<inputs @inputs="inputValue" :inputShow="true" inputPlace="请输入内容"></inputs>
					</div>
					<!-- <div class="dataSourceit-mai1-btn">
						<el-button type="danger" icon="el-icon-search">查询</el-button>
					</div> -->
				</div>
			</div>
			<div class="dataSourceR">
				<div class="RightsManagement-searchsMai">
					<el-button type="danger" icon="el-icon-plus" @click="clicknew()">新增</el-button>
					<!-- <el-button type="primary" v-permission="'Admin:Add'" @click="clickNew()" icon="el-icon-plus">新增人员</el-button> -->
				</div>
			</div>
		</div>
		<div class="dataSource-B">
			<el-table :data="tableData" border style="width: 100%" size="small">
				<template slot="empty">
					<noData></noData>
				</template>
				<el-table-column type="index" width="50" label="序号">
				</el-table-column>
				<el-table-column prop="T_id" label="数据源ID" :show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column label="数据源名称" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						{{scope.row.T_name}}
					</template>
				</el-table-column>
				<el-table-column label="SQL" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						{{scope.row.T_sql}}
					</template>
				</el-table-column>
				<el-table-column label="最近更新时间" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						{{scope.row.UpdateTime}}
					</template>
				</el-table-column>
				<el-table-column prop="address" label="操作" fixed="right" width="250">
					<template slot-scope="scope">
						<el-button size="small" @click="edItor(scope.row)">编辑</el-button>
						<el-button size="small" @click="Delect(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="RightsManagement-pagination">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
				:current-page="pages.page" :page-size="pages.page_z"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
		<el-dialog :title="title" :visible.sync="dialogVisible" :append-to-body="true">
			<div class="NewUser">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="70px" class="demo-ruleForm">
					<el-form-item label="名称" prop="T_name">
						<el-input v-model="ruleForm.T_name" placeholder="请填写数据源名称"></el-input>
					</el-form-item>
					<el-form-item label="SQL" prop="T_sql">
						<el-input type="textarea" v-model="ruleForm.T_sql" placeholder="请填写SQL"></el-input>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" @click="submitForm('ruleForm')">立即提交</el-button>
						<el-button @click="dialogVisible=false">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		dataSouList,
		dataSouAdd,
		dataSouEdit,
		dataSouDel
	} from "@/api/AdminManagement/dataSource.js"
	export default {
		data() {
			return {
				ruleForm: { //新增
					T_name: '', // 名称
					T_sql: '', //sql
				},
				rules: {
					T_name: [{
						required: true,
						message: '请填写数据源名称',
						trigger: 'blur'
					}],
					T_sql: [{
						required: true,
						message: '请填写sql',
						trigger: 'blur'
					}],
				},
				title: '',
				dialogVisible: false,
				tableData: [],
				Page_size: 0,
				pages: {
					T_name: '',
					page: 1,
					page_z: 10
				},
				total:0,
			}
		},
		created() {
			this.dataSouListApi()
		},
		methods: {
			handleSizeChange(e) { //每页多少条
				////console.log('每页多少条',e)
				this.pages.page_z = e
				this.dataSouListApi()
			},
			handleCurrentChange(e) { //跳转页
				////console.log('跳转页',e)
				this.pages.page = e
				this.dataSouListApi()
			},
			clicknew(e) {
				if (this.$refs.ruleForm) { //为了验证第一次触发表单验证，第二次打开还是触发
					this.$refs.ruleForm.resetFields();
				}
				this.dialogVisible = true
				this.title = '新增'
				delete this.ruleForm.T_id
				Object.keys(this.ruleForm).forEach(item=>{
					this.ruleForm[item] = ''
				})
			},
			edItor(value) {
				if (this.$refs.ruleForm) { //为了验证第一次触发表单验证，第二次打开还是触发
					this.$refs.ruleForm.resetFields();
				}
				this.dialogVisible = true
				this.title = '编辑'
				this.ruleForm.T_name = value.T_name
				this.ruleForm.T_sql = value.T_sql
				this.ruleForm.T_id = value.Id
			},
			Delect(value) {
				this.$confirm('此操作将永久删除数据源, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					dataSouDel({
						T_id:value.Id
					}).then(res=>{
						if(res.data.Code===200){
							this.$message.success('删除成功')
							this.pages.page = 1
							this.tableData = []
							this.dataSouListApi()
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			submitForm(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						////console.log('验证通过',this.title)
						if(this.title=='新增'){
							dataSouAdd(this.ruleForm).then(res => {
								////console.log('列表', res)
								if(res.data.Code===200){
									this.dialogVisible = false
									this.$message.success('新增成功')
									this.pages.page = 1
									this.tableData = []
									this.dataSouListApi()
								}
							})
						}else{
							dataSouEdit(this.ruleForm).then(res => {
								if(res.data.Code===200){
									this.dialogVisible = false
									this.$message.success('编辑成功')
									this.pages.page = 1
									this.tableData = []
									this.dataSouListApi()
								}
							})
						}
					} else {
						return false;
					}
				});
			},
			inputValue(val) {
				this.pages.T_name = val
				this.pages.page = 1
				this.dataSouListApi()
			},
			dataSouListApi() {
				dataSouList(this.pages).then(res => {
					////console.log('列表', res)
					this.Page_size = res.data.Data.Page_size
					this.total = res.data.Data.Num
					if(res.data.Data.Data!=null){
						this.tableData = res.data.Data.Data
					}
				})
			},
			
		}
	}
</script>

<style lang="scss">
	.dataSource {
		.dataSource-T {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: rgba(#fff, 1);
			height: 70px;
			padding: 15px 10px;
			box-shadow: 5px 5px 10px -10px #000;

			.dataSourceL {
				display: flex;
				align-items: center;

				.RightsManagement-searchs {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-left: 20px;

					.dataSourceit-mai1-btn {}

					.RightsManagement-searchs1 {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}

			.RightsManagement-searchsMai {
				display: flex;
				align-items: center;
				cursor: pointer;
			}
		}

		.dataSource-B {
			background: rgba(#fff, 1);
			margin: 10px;
			padding: 0 30px;
			// height: calc(100vh - 120px);
			overflow-y: auto;
		}
		.RightsManagement-pagination {
			// background: rgba(#31353e,1);
			padding:50px 30px;
			border-radius: 2px;
		}
	}
</style>